:global(#app) {

	.wrapper {
		border: none;
		border-radius: 3px;
		color: #6a808b;
		display: inline-block;
		outline: none;
		text-align: left;
		transition: background 0.3s ease;
		vertical-align: top;
	}

	.wrapperExtra {
		float: right;
		margin-top: 14px;
		margin-bottom: 6px;
		padding: 6px;
	}

	.actionButtonNoColor {
		box-shadow: 0 1px 0 0 rgba(9, 30, 66, 0.13);
		margin-top: 8px;
		overflow: hidden;
		padding: 6px 8px 6px 18px;
		text-align: left;
		text-overflow: ellipsis;
		transition: background 85ms ease;

		&:hover {
			box-shadow: 0 1px 0 0 rgba(9, 30, 66, 0.25);
		}
	}

	.actionIconNoColor {
		display: inline;
		margin-right: 8px;
	}

	.actionsCardId {
		margin-bottom: 24px;
	}

	.actionsTitleCard {
		color: #333333;
		font-size: 14px;
		font-weight: bolder;
		letter-spacing: 0.04em;
		margin-top: 16px;
		text-transform: uppercase;
		line-height: 20px;
		margin-bottom: -4px;
	}

	/* Colors */

	.gitDefault {
		background: #dce0e4;
	}

	.gitGrey {
		background: #838383;
	}

	.gitTeal {
		background: #009c95;
		color: #FFFFFF;
	}

	.gitBlue {
		background: #1678c2;
		color: #FFFFFF;
	}

	.gitGreen {
		background-color: #A5C261;
		color: #FFFFFF;
	}

	.gitRed {
		background-color: #FC736D;
		color: #FFFFFF;
	}

	.gitYellow {
		background-color: #F0982D;
		color: #FFFFFF;
	}
}
