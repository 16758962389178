:global(#app) {
  .version {
    font-weight: bold;
    text-align: center;
  }

	.logo {
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .wrapper {
    border: none;
    box-shadow: none;
  }
}
