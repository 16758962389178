:global(#app) {
	.wrapper {
		border: none;
		border-radius: 3px;
		color: #6a808b;
		display: inline-block;
		outline: none;
		text-align: left;
		transition: background 0.3s ease;
		vertical-align: top;

		margin-right: 5px;
	}

	/* Sizes */

	.wrapperTiny {
		font-size: 12px;
		line-height: 20px;
		padding: 0px 6px;
	}

	.wrapperSmall {
		font-size: 12px;
		line-height: 20px;
		padding: 2px 6px;
	}

	.wrapperMedium {
		line-height: 20px;
		padding: 6px 12px;
	}

	/* Colors */

	.gitDefault {
		background: #dce0e4;
	}

	.gitGrey {
		background: #838383;
	}

	.gitTeal {
		background: #009c95;
		color: #FFFFFF;
	}

	.gitBlue {
		background: #1678c2;
		color: #FFFFFF;
	}

	.gitGreen {
		background-color: #A5C261;
		color: #FFFFFF;
	}

	.gitRed {
		background-color: #FC736D;
		color: #FFFFFF;
	}

	.gitYellow {
		background-color: #F0982D;
		color: #FFFFFF;
	}
}
